import s from './UspBlock.module.css';
import EmbeddedImage from '@/components/Image/Image';
import { UspsBlockProps, UspProps } from '@/types/components/blocks';
import Link from 'next/link';
import React from 'react';
import { createLink } from '@/utils/createLinkProps';

const UspInner = ({
    data,
    arrow = false,
}: {
    data: UspProps;
    arrow?: boolean;
}) => {
    return (
        <>
            <div className={s.Usp__image}>
                {data.image && <EmbeddedImage imageDetails={data.image.img} />}
            </div>
            <h3 className={s.Usp__title}>{data.title}</h3>
            {data.text && (
                <p className={s.Usp__description}>
                    {data.text}
                    {arrow && (
                        <span
                            aria-hidden="true"
                            role="img"
                            className={s.Usp__arrow + ` material-icons`}>
                            arrow_forward
                        </span>
                    )}
                </p>
            )}
        </>
    );
};

const UspBlock = ({ data }: { data: UspsBlockProps }) => {
    const backgroundcolor = data?.bgcolor?.backgroundColor || '';

    return (
        <div className={backgroundcolor + ' ' + s[data?.textAlign]}>
            <div className={s.UspBlock}>
                <div className="wb-container">
                    <h2>{data.title}</h2>
                    <p>{data.text}</p>
                    <div className={s.Usps}>
                        {data.usps.map((usp, index) => {
                            const externalLinkProps = createLink(
                                usp.link[0]?.type
                            );
                            return (
                                <React.Fragment key={index}>
                                    {usp.link[0]?.value ? (
                                        <Link
                                            href={usp.link[0].value}
                                            className={s.Usp}
                                            {...externalLinkProps}>
                                            <UspInner data={usp} arrow />
                                        </Link>
                                    ) : (
                                        <div className={s.Usp}>
                                            <UspInner data={usp} />
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UspBlock;
