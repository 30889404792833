import React from 'react';
import s from './HeroBlock.module.css';
import ButtonBlock from '../ButtonBlock/ButtonBlock';

import { WbHero } from '@digital-engineering-experiences/wb-webcomponents';
import { HeroBlockProps } from '@/types/components/blocks';

const HeroBlock = ({ data }: { data: HeroBlockProps }) => {
    let backgroundImage;
    let video;

    if (data.media[0].type === 'video') {
        video = data.media[0].value.url;
        backgroundImage = data.media[0].value?.image.img.src;
    } else {
        video = undefined;
        backgroundImage = data.media[0].value?.img?.src;
    }

    return (
        <WbHero
            className={s.heroBlock}
            backgroundImage={backgroundImage}
            title={data.title}
            gradient={true}
            fixedheight={data.fixedHeight}
            text={data.text}
            video={video}>
            {data.buttonBlock.length !== 0 && (
                <ButtonBlock value={data.buttonBlock[0].value} />
            )}
        </WbHero>
    );
};

export default HeroBlock;
