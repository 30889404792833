import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { BasePageProps } from '@/types/containers/basePage';
const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));

const BasePage = ({
    children,
    seo,
    wagtailUserbar,
    siteName,
    navigation,
    siteSetting,
    search,
    translations,
    footer,
}: BasePageProps) => {
    const {
        seoHtmlTitle,
        seoMetaDescription,
        seoOgTitle,
        seoOgDescription,
        seoOgUrl,
        seoOgImage,
        seoOgType,
        seoTwitterTitle,
        seoTwitterDescription,
        seoTwitterUrl,
        seoTwitterImage,
        seoMetaRobots,
        canonicalLink,
    } = seo;
    const {
        logo,
        stickyHeader,
        favicon,
        lightColor,
        darkColor,
        primaryColor,
        secondaryColor,
    } = siteSetting;

    const languages = translations && [
        ...new Set([
            translations.currentLanguage,
            ...translations.translations.map(
                (translation) => translation.languageCode
            ),
        ]),
    ];

    return (
        <>
            <Head>
                <title>{seoHtmlTitle}</title>

                <style>
                    :root {'{'}
                    --default-light: {lightColor}; --default-dark: {darkColor};
                    --default-primary: {primaryColor}; --default-secondary:{' '}
                    {secondaryColor};{'}'}
                </style>

                <link
                    rel="icon"
                    href={favicon ? favicon.url : '/favicon.ico'}
                />
                {!!seoMetaDescription && (
                    <meta name="description" content={seoMetaDescription} />
                )}
                {!!seoOgTitle && (
                    <meta property="og:title" content={seoOgTitle} />
                )}
                {!!seoOgDescription && (
                    <meta
                        property="og:description"
                        content={seoOgDescription}
                    />
                )}
                {!!seoOgUrl && <meta property="og:url" content={seoOgUrl} />}
                {!!seoOgImage && (
                    <meta property="og:image" content={seoOgImage} />
                )}
                {!!seoOgType && <meta property="og:type" content={seoOgType} />}
                {!!seoTwitterTitle && (
                    <meta property="twitter:title" content={seoTwitterTitle} />
                )}
                {!!seoTwitterDescription && (
                    <meta
                        property="twitter:description"
                        content={seoTwitterDescription}
                    />
                )}
                {!!seoTwitterUrl && (
                    <meta property="twitter:url" content={seoTwitterUrl} />
                )}
                {!!seoTwitterImage && (
                    <meta property="twitter:image" content={seoTwitterImage} />
                )}
                <meta name="robots" content={seoMetaRobots} />
                {!!canonicalLink && (
                    <link rel="canonical" href={canonicalLink} />
                )}
            </Head>
            <Header
                navigation={navigation}
                logo={logo}
                stickyHeader={stickyHeader}
                search={search}
                siteName={siteName}
                translationsCurrentPage={translations.translations}
                availableLanguages={languages}
            />
            <div id="maincontent" className="BasePage">
                {children}
            </div>
            {footer && <Footer footer={footer} />}
            {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
        </>
    );
};

export default BasePage;
