import React, { useEffect } from 'react';
import { RawHtmlProps } from '../../types/components/blocks';

const RawHtml = ({ html }: RawHtmlProps) => {
    useEffect(() => {
        const links = document.querySelectorAll('.richtexteditor a');
        links.forEach((link) => {
            const href = link.getAttribute('href');
            if (href && isExternal(href)) {
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
            }
        });
    }, [html]);

    const isExternal = (url: string) => {
        return url.startsWith('http') || url.startsWith('//');
    };

    return (
        <div
            className="richtexteditor"
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};

export default RawHtml;
