import React from 'react';
import { createLink } from '../../utils/createLinkProps';
import { ButtonProps } from '../../types/components/button';
import { WbButton } from '@digital-engineering-experiences/wb-webcomponents';

const Button = ({
    buttonType,
    buttonSize,
    buttonState,
    buttonIcon,
    buttonIconPosition,
    buttonText,
    buttonLink,
    onClick,
}: ButtonProps) => {
    let externLinkProps: any = {};
    let link: string | undefined;

    if (buttonLink && buttonLink.length > 0) {
        externLinkProps = createLink(buttonLink[0].type);

        link = buttonLink[0].value;
    }

    const state = buttonState === 'disabled' ? 'disabled' : 'default';
    const iconLeft = buttonIconPosition === 'left' ? true : false;

    return (
        <WbButton
            style={buttonType}
            size={buttonSize}
            state={state}
            iconType={buttonIcon}
            iconLeft={iconLeft}
            {...(link && { href: link })}
            {...externLinkProps}
            label={buttonText}
            onClick={onClick}></WbButton>
    );
};

export default Button;
