import React from 'react';
import HeroBlock from './HeroBlock';
import ImageBlock from './ImageBlock';
import CollapsibleItemBlock from './CollapsibleItemBlock';
import ButtonListBlock from './ButtonListBlock';
import TwoColumnBlock from './TwoColumnBlock';
import VideoBlock from './VideoBlock';
import RichTextBlock from './RichTextBlock';
import CardBlock from './CardBlock';
import IFrameBlock from './IFrameBlock';
import UspBlock from './UspBlock';
import ListPreviewBlock from './ListPreviewBlock';
import PlusDividerBlock from './PlusDividerBlock';
import { ContentBlocksProps } from '@/types/components/blocks';
import TimelineBlock from './TimelineBlock/TimelineBlock';

const ContentBlocks = ({ content }: ContentBlocksProps) => {
    return (
        <React.Fragment>
            {content?.map((contentItem) => {
                switch (contentItem.type) {
                    case 'hero_block':
                        return (
                            <HeroBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'image_block':
                        return (
                            <ImageBlock
                                key={contentItem.id}
                                data={contentItem.value}
                                imageDetails={contentItem.value.image.img}
                            />
                        );
                    case 'video_block':
                        return (
                            <VideoBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'collapsible_item_block':
                        return (
                            <CollapsibleItemBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'two_column_block':
                        return (
                            <TwoColumnBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'rich_text_block':
                        return (
                            <RichTextBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'cards_block':
                        return (
                            <CardBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'iframe_block':
                        return (
                            <IFrameBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'buttonlist_block':
                        return (
                            <ButtonListBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'usp_block':
                        return (
                            <UspBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'timeline_block':
                        return (
                            <TimelineBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'plus_divider_block':
                        return (
                            <PlusDividerBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );
                    case 'list_preview_block':
                        return (
                            <ListPreviewBlock
                                key={contentItem.id}
                                data={contentItem.value}
                            />
                        );

                    default:
                        null;
                }
            })}
        </React.Fragment>
    );
};

export default ContentBlocks;
