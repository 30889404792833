import { WbCard } from '@digital-engineering-experiences/wb-webcomponents';
import { ListPreviewBlockProps } from '@/types/components/blocks';
import s from './ListPreviewBlock.module.css';
import { useRouter } from 'next/router';

const ListPreviewBlock = ({ data }: { data: ListPreviewBlockProps }) => {
    const backgroundcolor = data.bgcolor?.backgroundColor || '';
    const router = useRouter();

    const getLanguage = router.locale ? router.locale : 'nl';
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    return (
        <div className={s.ListPreviewBlock + ' ' + backgroundcolor}>
            <div className="wb-container">
                <div className={s[data?.introTextAlign]}>
                    <h2 className="list-preview-block__title">{data.title}</h2>
                    <p>{data.description}</p>
                </div>
                <div className="list-preview-block__content">
                    <ul className="list-preview-block__list">
                        {data.listItems.map((item, index) => (
                            <li
                                className={s.ListPreviewBlock__ListItem}
                                key={`list_preview ${index}`}>
                                <WbCard
                                    cardType="horizontal"
                                    cardtitle={item.summary.title}
                                    description={item.summary.description}
                                    date={
                                        item.lastPublishedAt &&
                                        new Date(
                                            item.lastPublishedAt
                                        ).toLocaleString(
                                            getLanguage,
                                            dateOptions as Intl.DateTimeFormatOptions
                                        )
                                    }
                                    img={item.summary?.image?.img?.src}
                                    link={item.link}
                                    imgHoverColor={true}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ListPreviewBlock;
