import s from './IFrameBlock.module.css';
import { IFrameBlockProps } from '@/types/components/blocks';

export default function IFrameBlock({ data }: { data: IFrameBlockProps }) {
    const width = data.width || 600;
    const height = data.height || 1000;

    return (
        <div className={s['iframeContainer']}>
            {data.title && <h2>{data.title}</h2>}
            <div className={s['iframeBlock']}>
                <iframe
                    title={data.title}
                    src={data.src}
                    width={width}
                    height={height}
                    allowFullScreen
                    style={{ overflow: 'hidden', border: 'none' }}
                />
            </div>
        </div>
    );
}
