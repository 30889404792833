import React from 'react';
import s from './PlusDividerBlock.module.css';
import { PlusDividerBlockProps } from '@/types/components/blocks';

const PlusDividerBlock = ({ data }: { data: PlusDividerBlockProps }) => {
    return (
        <span
            className={
                s[data.position] + ' ' + s[data.color] + ' ' + s.divider
            }></span>
    );
};

export default PlusDividerBlock;
