import React from 'react';
import s from './ImageBlock.module.css';
import { createLink } from '../../../utils/createLinkProps';
import Link from 'next/link';
import EmbeddedImage from '@/components/Image/Image';
import { ImageBlockProps } from '@/types/components/blocks';

const ImageBlock = ({ data, imageDetails }: ImageBlockProps) => {
    const backgroundcolor =
        data?.imageProperties.bgcolor?.backgroundColor || '';

    const externLinkProps =
        data.imageProperties.linkImage &&
        data.imageProperties.linkImage.length !== 0 &&
        createLink(data.imageProperties.linkImage[0].type);

    return (
        <div className={backgroundcolor}>
            <div className={s.imageBlock}>
                <div className={`wb-container ${s.imageBlock__container}`}>
                    {data.imageProperties.linkImage &&
                    data.imageProperties.linkImage.length !== 0 ? (
                        <Link
                            passHref={
                                data.imageProperties.linkImage[0].type ==
                                'external'
                            }
                            href={data.imageProperties.linkImage[0].value}
                            {...externLinkProps}>
                            <EmbeddedImage
                                data={data}
                                imageDetails={imageDetails}
                            />
                        </Link>
                    ) : (
                        <div>
                            <EmbeddedImage
                                data={data}
                                imageDetails={imageDetails}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageBlock;
