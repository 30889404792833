import React from 'react';
import s from './CollapsibleItemBlock.module.css';
import RawHtml from '../../RawHtml/RawHtml';
import { WbCollapsible } from '@digital-engineering-experiences/wb-webcomponents';
import { CollapsibleItemBlockProps } from '@/types/components/blocks';

const CollapsibleItemBlock = ({
    data,
}: {
    data: CollapsibleItemBlockProps;
}) => {
    const backgroundcolor = data.bgcolor?.backgroundColor || '';

    return (
        <div className={`${backgroundcolor} `}>
            <WbCollapsible
                className={s.collapsibleItem}
                collapsed={data.collapsed}
                question={data.question}>
                <RawHtml html={data.answer} />
            </WbCollapsible>
        </div>
    );
};

export default CollapsibleItemBlock;
