import React from 'react';
import Button from '../../Button';
import { ButtonBlockProps } from '@/types/components/blocks';
import s from './ButtonBlock.module.css';

const ButtonBlock = ({ value }: ButtonBlockProps) => {
    const buttons = value.buttons;
    return (
        <div className={`${s.buttonBlock} ${s[value.buttonsAlign]}`}>
            {buttons.map((button) => {
                return (
                    <Button
                        buttonType={button.value.buttonType}
                        buttonSize={button.value.buttonSize}
                        buttonState={button.value.buttonState}
                        buttonText={button.value.buttonText}
                        buttonIcon={button.value.buttonIcon}
                        buttonIconPosition={button.value.buttonIconPosition}
                        buttonLink={button.value.buttonLink}
                        key={button.id}></Button>
                );
            })}
        </div>
    );
};

export default ButtonBlock;
