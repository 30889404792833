import { useRouter } from 'next/router';
import s from './TimelineBlock.module.css';

import React from 'react';
import { TimelineProps, TimelineBlockProps } from '@/types/components/blocks';

/* Sorts the items based on the date */
const sortByDateProperty = (timelineArray: TimelineProps[]) => {
    const newArray = [...timelineArray];

    const sortedArray = newArray.sort((a: any, b: any) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
    });
    return sortedArray;
};

/* Calculates the status of timeline based on the item date before, current date and the time it needs to be completed */
const getProgress = (activeDate: any, nextDate: any) => {
    const oldDate = new Date(activeDate);
    const currentDate = new Date();
    const newDate = new Date(nextDate);

    const totalTime = newDate.getTime() - oldDate.getTime();
    const elapsedTime = currentDate.getTime() - oldDate.getTime();
    const progressValue = (elapsedTime / totalTime) * 100;

    return progressValue + '%';
};

/* Renders the infromation from a timeline item*/
const renderTimelineContainer = (
    { item }: { item: any },
    getLanguage: Intl.LocalesArgument
) => {
    const formattedDate = new Date(item.date).toLocaleDateString(getLanguage, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    return (
        <div className={s.timelineinfobox}>
            {item.label ? (
                <p className={s.timeline_label}>{item.label}</p>
            ) : (
                <p className={s.timeline_label}>{formattedDate}</p>
            )}{' '}
            <h3 className={s.timeline_title}>{item.title}</h3>
            <p className={s.timeline_text}>{item.text}</p>
        </div>
    );
};

const TimelineBlock = ({ data }: { data: TimelineBlockProps }) => {
    const router = useRouter();
    const getLanguage = router.locale ? router.locale : 'nl';
    const backgroundcolor = data.bgcolor?.backgroundColor || '';

    /* Sorts the items, turning this off is possible, change the sortedArray.map on line 41 to timelineitem.map */
    const sortedArray = sortByDateProperty(data.timelineitems);

    const now = +new Date();

    return (
        <div className={backgroundcolor + ' ' + s.timeline_block}>
            <div className="wb-container">
                {(data.title || data.text) && (
                    <div className={s.timeline_block__inner}>
                        {data.title && <h2>{data.title}</h2>}
                        {data.text && <p>{data.text}</p>}
                    </div>
                )}

                <div>
                    {sortedArray.map((item: TimelineProps, index: number) => {
                        const progress =
                            now < +new Date(item.date)
                                ? 'future'
                                : now < +new Date(sortedArray[index + 1]?.date)
                                ? 'active'
                                : 'past';
                        return (
                            <div
                                className={`${s.timelinerow}`}
                                key={index}
                                data-type={progress}>
                                <div className={s.timelineiconbox}>
                                    {index < sortedArray.length - 1 && (
                                        <span
                                            className={s.line}
                                            style={{
                                                height:
                                                    sortedArray[index + 1] &&
                                                    progress === 'active'
                                                        ? getProgress(
                                                              item.date,
                                                              sortedArray[
                                                                  index + 1
                                                              ]?.date
                                                          )
                                                        : '100%',
                                            }}></span>
                                    )}
                                    <div className={`${s.iconWrapper} `}>
                                        <span
                                            role="img"
                                            aria-hidden="true"
                                            className="material-icons">
                                            {item.icon}
                                        </span>
                                    </div>
                                </div>
                                {renderTimelineContainer({ item }, getLanguage)}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TimelineBlock;
