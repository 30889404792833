function createLink(linkType: string) {
    let externLinkProps: boolean | { target: string; rel: string } = {
        target: '_blank',
        rel: 'noopener noreferrer',
    };

    if (linkType === 'intern') {
        externLinkProps = false;
    }

    return externLinkProps;
}

export { createLink };
