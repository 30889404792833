import React from 'react';
import s from './ButtonListBlock.module.css';
import ButtonBlock from '../ButtonBlock/ButtonBlock';
import { ButtonListBlockProps } from '@/types/components/blocks';

const ButtonListBlock = ({ data }: { data: ButtonListBlockProps }) => {
    const backgroundcolor = data.bgcolor?.backgroundColor || '';

    return (
        <div className={backgroundcolor + ' ' + s.ButtonListBlock}>
            <div className="wb-container">
                <div
                    className={
                        s.ButtonListBlock__inner + ' ' + s[data?.textAlign]
                    }>
                    {data.title && <h2>{data.title}</h2>}
                    {data.text && <p>{data.text}</p>}
                </div>
                <div>
                    {data.buttonBlock?.length !== 0 && (
                        <ButtonBlock value={data.buttonBlock[0].value} />
                    )}
                </div>
            </div>
        </div>
    );
};
export default ButtonListBlock;
