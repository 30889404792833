import React from 'react';
import Link from 'next/link';
import s from './Footer.module.css';
import { FooterProps } from '@/types/components/footer';

const Footer = ({ footer }: FooterProps) => {
    function getYear() {
        return new Date().getFullYear();
    }

    return (
        <footer className={s.Footer}>
            <span>
                {getYear()} © {footer.company}
            </span>
            {footer.content && (
                <nav>
                    <ul>
                        {footer.content.map((item, index) => {
                            if (item.type === 'button') {
                                return (
                                    <li key={index}>
                                        <Link
                                            target={
                                                item.value.buttonLink.type ===
                                                'extern'
                                                    ? '_blank'
                                                    : undefined
                                            }
                                            href={item.value.buttonLink.value.toString()}>
                                            {item.value.buttonText}
                                        </Link>
                                    </li>
                                );
                            } else if (item.type === 'file') {
                                return (
                                    <li key={index}>
                                        <a
                                            target="_blank"
                                            href={item.value.fileLink}>
                                            {item.value.fileTitle}
                                        </a>
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ul>
                </nav>
            )}
        </footer>
    );
};

export default Footer;
